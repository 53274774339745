import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import AutoCADCards from '../components/AutoCAD-Products'
import HeroImage from '../components/HeroImage'



const AutoCAD = () => {
    return (
        <div>
            <Navbar />
            <HeroImage heading=' AutoCAD Plant 3D utilities.' text='Our AutoCAD Plant 3D utilities.' />
            <AutoCADCards />
            <Footer />
        </div>
    )
}

export default AutoCAD
