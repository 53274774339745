import React, { useState } from "react";
import AutoCADList from "./AutoCAD-ProductList.js";
import SearchBar from "./SearchBar.js";
import EmptyList from "./EmptyList.js";
import { autoCADList } from "../Config/Data.js";

const AutoCAD = () => {
  const [autoCAD, setAutoCAD] = useState(autoCADList);
  const [searchKey, setSearchKey] = useState('');

  // Search submit
  const handleSearchBar = (e) => {
    e.preventDefault();
    handleSearchResults();
  };

  // Search for blog by category
  const handleSearchResults = () => {
    const allAutoCAD = autoCADList;
    const filteredAutoCAD = allAutoCAD.filter((autoCAD) =>
    autoCAD.category.toLowerCase().includes(searchKey.toLowerCase().trim())
    );
    setAutoCAD(filteredAutoCAD);
  };

  // Clear search and show all blogs
  const handleClearSearch = () => {
    setAutoCAD(autoCADList);
    setSearchKey('');
  };

  return (
    <div>
     

      {/* Search Bar */}
      <SearchBar
        value={searchKey}
        clearSearch={handleClearSearch}
        formSubmit={handleSearchBar}
        handleSearchKey={(e) => setSearchKey(e.target.value)}
      />

      {/* Blog List & Empty View */}
      {!autoCAD.length ? <EmptyList /> : <AutoCADList products={autoCADList} />}
    </div>
  );
};

export default AutoCAD;
