import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { autoCADList } from "../Config/Data";
import { Link } from "react-router-dom";
import Chip from "./index.js";
import EmptyList from "./EmptyList";
import "./AutoCAD-product.css";

const AutoCAD = () => {
  const { id } = useParams();
  const [autoCAD, setAutoCAD] = useState(null);

  useEffect(() => {
    let autoCAD = autoCADList.find((autoCAD) => autoCAD.id === parseInt(id));

    if (autoCAD) {
      setAutoCAD(autoCAD);
    }
  }, [id]);
  if ( autoCADList.subCover3  === undefined  ) {
    return (
      <>
        <Link className="autoCAD-goBack" to="/AutoCAD-Plant-3D-utilities">
          <span> &#8592;</span> <span>Go Back</span>
        </Link>
        {autoCAD ? (
          <div className="autoCAD-wrap">
            <header>
              <h1>{autoCAD.title}</h1>
              <div className="autoCAD-subCategory">
                {autoCAD.subCategory.map((category, i) => (
                  <div key={i}>
                    <Chip label={category} />
                  </div>
                ))}
              </div>
            </header>
            <div className="Cover">
              <div className="column">
                <img src={autoCAD.cover} alt="cover" />
              </div>
              <br />
              <div className="column">
                <img src={autoCAD.subCover1} alt="" />
              </div>
              <br />
              <div className="column">
                <img src={autoCAD.subCover2} alt="" />
              </div>
              <br />
              <div className="column">
                <img src={autoCAD.subCover3} alt="" />
              </div>
              <br />
            </div>
            <p className="autoCAD-desc">{autoCAD.subDescription}</p>
          </div>
        ) : (
          <EmptyList />
        )}
      </>
    );
  } else if (autoCADList.subCover2  === undefined && autoCADList.subCover1  === undefined) {
    return (
      <>
        <Link className="autoCAD-goBack" to="/AutoCAD-Plant-3D-utilities">
          <span> &#8592;</span> <span>Go Back</span>
        </Link>
        {autoCAD ? (
          <div className="autoCAD-wrap">
            <header>
              <h1>{autoCAD.title}</h1>
              <div className="autoCAD-subCategory">
                {autoCAD.subCategory.map((category, i) => (
                  <div key={i}>
                    <Chip label={category} />
                  </div>
                ))}
              </div>
            </header>
            <div className="Cover">
              <div className="column">
                <img src={autoCAD.cover} alt="cover" />
              </div>
              <br />
              <div className="column">
                <img src={autoCAD.subCover1} alt="" />
              </div>
              <br />
              <div className="column">
                <img src={autoCAD.subCover2} alt="" />
              </div>
            </div>
            <p className="autoCAD-desc">{autoCAD.subDescription}</p>
          </div>
        ) : (
          <EmptyList />
        )}
      </>
    );
  } else {
    return (
      <>
        <Link className="autoCAD-goBack" to="/AutoCAD-Plant-3D-utilities">
          <span> &#8592;</span> <span>Go Back</span>
        </Link>
        {autoCAD ? (
          <div className="autoCAD-wrap">
            <header>
              <h1>{autoCAD.title}</h1>
              <div className="autoCAD-subCategory">
                {autoCAD.subCategory.map((category, i) => (
                  <div key={i}>
                    <Chip label={category} />
                  </div>
                ))}
              </div>
            </header>
            <div className="Cover">
              <div className="column">
                <img src={autoCAD.cover} alt="cover" />
              </div>
              <br />
              <div className="column">
                <img src={autoCAD.subCover1} alt="cover" />
              </div>
            </div>
            <p className="autoCAD-desc">{autoCAD.subDescription}</p>
          </div>
        ) : (
          <EmptyList />
        )}
      </>
    );
  };   
};

export default AutoCAD;
