import React from "react";
import Chip from "./index.js";
import "./BlogList.css";
//import BlogItem from "./BlogItem";
import { Link } from "react-router-dom";

const BlogItem = ({ blog: { id, description, title, category, cover,subCovers  } }) => {
 
  return (
    <div className="right2">
      <div className="blogItem-wrap">
        <div className="blogItem-cover ">
          <img src={cover} className="img" alt="{blog}" />
          <Chip label={category} />
          <p className="blogItem-title">{title}</p>
          <p className="blogItem-desc">{description}</p>
          <footer>
            <div className="blogItem-author">
              {/* <img src="" alt="ava>tar" /> */}
            </div>
            <Link className="blogItem-link" to={`/Blog/${id}`}>
              <h2> ➡️ </h2>
            </Link>
          </footer>
        </div>
        <br />
      </div>
    </div>
  );
};

export default BlogItem;
