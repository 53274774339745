import React from "react";
import { Routes, Route } from "react-router-dom";
// Blog from './pages/Blog'
import Home from "./routes/Home";
import Clients from "./routes/Client";
import Services from "./routes/Services";
import Projects from "./routes/Projects";
import Products from "./routes/Autodesk-Products";
//import Blog from './routes/Blog';
import Blog from "./components/Blog";
import Contact from "./routes/Contact";
import Product from "./components/AutoCAD-product";
import IT from "./routes/IT";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Projects" element={<Projects />} />
        <Route path="/AutoCAD-Plant-3D-utilities" element={<Products />} />
        <Route path="/IT" element={<IT />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Clients" element={<Clients />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/blog/:id" element={<Blog />} />
        <Route path="/AutoCAD/:id" element={<Product />} />
      </Routes>

      {/* <div className="App">
        <header className="App-header"></header>
        <p style={{ textAlign: "center", fontWeight: "bold" }}>
          {this.state.apiResponse}
        </p>
      </div> */}
    </>
  );
}

export default App;
