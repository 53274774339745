/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./FormStyles.css";
import Logo from "../assets/Slide2.gif";
import { FaMailBulk, FaPhone } from "react-icons/fa";

const Form = () => {
  return (
    <div className="container">
      <img className="Logo" src={Logo} alt="Logo" />
      <div className="centered">
        <br/>
        <br/>
        <div className="phone">
          <h4>
            <FaPhone
              size={20}
              style={{ color: "#ffffff", marginRight: "2rem" }}
            />{" "}
            meir - 972-52-8652221
          </h4>
        </div>
        <br />
        <div className="email">
          <h4>
            <FaMailBulk
              size={20}
              style={{ color: "#ffffff", marginRight: "2rem" }}
            />{" "}
            meir_w@itibam.co.il
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Form;
