import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { blogList } from "../Config/Data";
import { Link } from "react-router-dom";
import Chip from "./index.js";
import EmptyList from "./EmptyList";
import "./Blog.css";

const Blog = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    let blog = blogList.find((blog) => blog.id === parseInt(id));

    if (blog) {
      setBlog(blog);
    }
  }, [id]);
  // console.log(blog)
  if (blogList.subCover2 === undefined) {
    return (
      <>
        <Link className="blog-goBack" to="/projects">
          <span> &#8592;</span> <span>Go Back</span>
        </Link>
        {blog ? (
          <div className="blog-wrap">
            <header>
              <h1>{blog.title}</h1>
              <div className="blog-subCategory">
                {blog.subCategory.map((category, i) => (
                  <div key={i}>
                    <Chip label={category} />
                  </div>
                ))}
              </div>
            </header>
            <div className="Cover">
              <div className="column">
                <img src={blog.cover} alt="cover" />
              </div>
              <br />
              <div className="column">
                <img src={blog.subCover1} alt="cover" />
              </div>
              <br />
              <div className="column">
                <img src={blog.subCover2} alt="" />
              </div>
            </div>
            <p className="blog-desc">{blog.subDescription}</p>
          </div>
        ) : (
          <EmptyList />
        )}
      </>
    );
  } else {
    return (
      <>
        <Link className="blog-goBack" to="/projects">
          <span> &#8592;</span> <span>Go Back</span>
        </Link>
        {blog ? (
          <div className="blog-wrap">
            <header>
              <h1>{blog.title}</h1>
              <div className="blog-subCategory">
                {blog.subCategory.map((category, i) => (
                  <div key={i}>
                    <Chip label={category} />
                  </div>
                ))}
              </div>
            </header>
            <div className="Cover">
              <div className="column">
                <img src={blog.cover} alt="cover" />
              </div>
              <br />
              <div className="column">
                <img src={blog.subCover1} alt="cover" />
              </div>
            </div>
            <p className="blog-desc">{blog.subDescription}</p>
          </div>
        ) : (
          <EmptyList />
        )}
      </>
    ); 
  };  
};

export default Blog;
