import React from "react";
import "./FooterStyles.css";
import { FaFacebook, FaGoogle } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="form">
      <div className="footer">
        <div className="footer-container">

          <div className="right">
            <h4 className="h4">About the company</h4>
            <p className="company">
              "Since 2007 we are: Developing solutions for Engineering firms &
              Industrial plants. Providing support for AutoCAD users , including
              developing add-ons & integrations with office apps & ERP.
              Developing Add-ons for Office & MS-project. Developing Mobile &
              Web application , specialized for Engineering requests. Using wide
              range of developing languages : C# , Angular , React Native ,JS
              ,TS , Node.js , VB , VB6 , SQL server ."
            </p>
            <p className="res">SOCIALLY , RESPONSIBLE , ACCURATE , INNOVATIVE , CREATIVE , EFFECTIVE , PRECISE</p>
            <div className="social">
              <FaFacebook
                size={30}
                style={{ color: "#ffffff", marginRight: "1rem" }}
              />
              <FaGoogle
                size={30}
                style={{ color: "#ffffff", marginRight: "1rem" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
