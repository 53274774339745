import React from "react";
import "./ServicesStyles.css";

import { Link } from "react-router-dom";

const Services = () => {
  return (
    <div className="training">
      <div className="left">
        <h1>• AutoCAD products.</h1>
      </div>
      <br/>
      <br/>
      <div className="left">
        <h1>• AutoCAD Plant 3D Specialist.</h1>
      </div>
      <br/>
      <br/>
      <div className="left">
        <h1>• Autodesk Vault based engineering information management.</h1>
      </div>
      <br/>
      <br/>
      <div className="left">
        <h1>
          • Complementary software developments (API add-ons) for a variety of
          products such as: AutoCAD, CADWorx, Vault, MS project, Excel.
        </h1>
      </div>
      <br/>
      <br/>
      <div className="left">
        <h1>
          • Sales and service of Guideware software A product for managing LDAR
          leak control adapted to an Israeli standard.
        </h1>
      </div>
      <br/>
      <br/>
      <div className="left">
        <h1>• Building dedicated business websites.</h1>
      </div>
      <br/>
      <br/>
      <div className="left">
        <h1>• Development of applications and smartphones.</h1>
      </div>
      <br/>
      <br/>
      <div className="left">
        <h1>• Dedicated software developments according to customer needs.</h1>
      </div>
      <br/>
      <br/>
      <Link to="/Contact">
        <button className="btn" data-hover="please specify the content you chose while contacting us"><h4>Contact Us</h4></button>
      </Link>
    </div>
  );
};

export default Services;
