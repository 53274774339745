import Algatech2 from "../assets/images/Capture2.PNG";
import Algatech3 from "../assets/images/Capture3.PNG";
import Algatech4 from "../assets/images/Capture4.PNG";
import Jsa1 from "../assets/Site material/JSA/JSA1.PNG";
import Jsa2 from "../assets/Site material/JSA/jsa2.PNG";
import Ldar from "../assets/Site material/LDAR/LDAR.PNG";
import Ldar2 from "../assets/Site material/LDAR/ldar2.PNG";
import Renovation from "../assets/Site material/Renovation/Renovation.PNG";
import Renovation2 from "../assets/Site material/Renovation/Renovation2.PNG";
import Renovation3 from "../assets/Site material/Renovation/RenovationPortal.PNG";
import Storage from "../assets/Site material/Storage/Storage.PNG";
import Storage2 from "../assets/Site material/Storage/Storage2.PNG";
import Storage3 from "../assets/Site material/Storage/StoragePortal.PNG";
import Surveys from "../assets/Site material/Surveys/Survey1.PNG";
import Surveys2 from "../assets/Site material/Surveys/Survey2.PNG";
import CompareBOM1 from "../assets/AutoCad/CompareBOM1.PNG";
import CompareBOM2 from "../assets/AutoCad/CompareBOM2.PNG";
import CreatePDF1 from "../assets/AutoCad/CreatePDF1.PNG";
import CreatePDF2 from "../assets/AutoCad/CreatePDF2.PNG";
import IsometricBOM1 from "../assets/AutoCad/IsometricBOM1.PNG";
import IsometricBOM2 from "../assets/AutoCad/IsometricBOM2.PNG";
import IsometricBOM3 from "../assets/AutoCad/IsometricBOM3.PNG";
import IsometricBOM4 from "../assets/AutoCad/IsometricBOM4.PNG";
import ModelMTO1 from "../assets/AutoCad/ModelMTO1.PNG";
import ModelMTO2 from "../assets/AutoCad/ModelMTO2.PNG";
import ModelMTO3 from "../assets/AutoCad/ModelMTO3.PNG";
import PredesignMTO1 from "../assets/AutoCad/PredesignMTO1.PNG";
import titleFill1 from "../assets/AutoCad/titleFill1.PNG";
import titleFill2 from "../assets/AutoCad/titleFill2.PNG";
import titleFill3 from "../assets/AutoCad/titleFill3.PNG";



export const blogList = [
  {
    id: 2,
    title: "ALGO",
    category: "Algatech",
    subCategory: ["Algatech"],
    description: " algae tracking app and...",
    subDescription: " algae tracking app and data entry.",
    authorName: "Meir Weiss",
    authorAvatar: "../assets/author.jpg",
    createdAt: "June 03, 2021",
    cover: Algatech2,
    subCover1: Algatech3,
    subCover2: Algatech4,
    subCovers: [Algatech2, Algatech3, Algatech4],
  },

  {
    id: 3,
    title: "JSA survey Application",
    category: "Jsa",
    subCategory: ["Jsa"],
    description: "Dedicated application for ...",
    subDescription:
      "Dedicated application for conducting risk surveys using the method JSA.",
    authorName: "Meir Weiss",
    authorAvatar: "../assets/author.jpg",
    createdAt: "June 03, 2021",
    cover: Jsa1,
    subCover1: Jsa2,
    subCovers: [Jsa1, Jsa2],
  },

  {
    id: 4,
    title: "Leak inspection application LDAR",
    category: "Ldar",
    subCategory: ["Ldar"],
    description: "An app to...",
    subDescription: "An app to document leaks in the method LDAR",
    authorName: "Meir Weiss",
    authorAvatar: "../assets/author.jpg",
    createdAt: "June 03, 2021",
    cover: Ldar,
    subCover1: Ldar2,
    subCovers: [Ldar, Ldar2],
  },

  {
    id: 5,
    title: "Plant componnet's Renovation application",
    category: "Renovation",
    subCategory: ["Renovation"],
    description: "Tracking certificate app...",
    subDescription: " Tracking certificate app renovating factory equipment.",
    authorName: "Meir Weiss",
    authorAvatar: "../assets/author.jpg",
    createdAt: "June 03, 2021",
    cover: Renovation,
    subCover1: Renovation2,
    subCover2: Renovation3,
    subCovers: [Renovation, Renovation2, Renovation3],
  },
  {
    id: 6,
    title: "Storage Components stock handling Application With SAP integration",
    category: "Storage",
    subCategory: ["Storage"],
    description: "An app for maintaining...",
    subDescription:
      "An app for maintaining warehouse details is connected to SAP.",
    authorName: "Meir Weiss",
    authorAvatar: "../assets/author.jpg",
    createdAt: "June 03, 2021",
    cover: Storage,
    subCover1: Storage2,
    subCover2: Storage3,
    subCovers: [Storage, Storage2, Storage3],
  },
  {
    id: 7,
    title: "Industrial Plant's Survey application",
    category: "Surveys",
    subCategory: ["Surveys"],
    description: "Application for...",
    subDescription: "Application for conducting factory surveys.",
    authorName: "Meir Weiss",
    authorAvatar: "../assets/author.jpg",
    createdAt: "June 03, 2021",
    cover: Surveys,
    subCover1: Surveys2,
    subCovers: [Surveys, Surveys2],
  },
];




export const autoCADList = [
  {
    id: 2,
    title: "BOM",
    category: "Compare BOM",
    subCategory: ["Compare BOM"],
    description: " Provide ability to compare two MTO excel files that...",
    subDescription: " Provide ability to compare two MTO excel files that was produced by Isometric MTO application or Model MTO application & report for changes.",
    authorName: "Meir Weiss",
    createdAt: "June 03, 2021",
    cover: CompareBOM1,
    subCover1: CompareBOM2,
  },
  {
    id: 3,
    title: "PDF",
    category: "Create PDF",
    subCategory: ["Create PDF"],
    description: " Create multiple...",
    subDescription: "Create multiple PDF file from selected drawings.",
    authorName: "Meir Weiss",
    createdAt: "June 03, 2021",
    cover: CreatePDF1,
    subCover1: CreatePDF2,
  },
  {
    id: 4,
    title: "MTO",
    category: "Isometric MTO",
    subCategory: ["Isometric MTO"],
    description: " Create MTO summarization report from AutoCAD plant 3d ...",
    subDescription: " Create MTO summarization report from AutoCAD plant 3d Project’s Isometrics based on predefined template & export the report the Excel file. Special functionality for Isometric that was modified manually after producing.",
    authorName: "Meir Weiss",
    createdAt: "June 03, 2021",
    cover: IsometricBOM1,
    subCover1: IsometricBOM2,
    subCover2: IsometricBOM3,
    subCover3: IsometricBOM4,
  },
  {
    id: 5,
    title: "Model",
    category: "Model MTO",
    subCategory: ["Model MTO"],
    description: " Create MTO summarization report from AutoCAD plant 3d...",
    subDescription: " Create MTO summarization report from AutoCAD plant 3d Project’s Model(s) based on predefined template & export the report the Excel file.",
    authorName: "Meir Weiss",
    createdAt: "June 03, 2021",
    cover: ModelMTO1,
    subCover1: ModelMTO2,
    subCover2: ModelMTO3,
  },
  {
    id: 6,
    title: "Predesign",
    category: "Predesign MTO",
    subCategory: ["Predesign MTO"],
    description: " Create MTO from AutoCAD Plant 3d Specs without...",
    subDescription: " Create MTO from AutoCAD Plant 3d Specs without Models. Users can collect items from piping Specs & create detailed MTO. .",
    authorName: "Meir Weiss",
    createdAt: "June 03, 2021",
    cover: PredesignMTO1,
  },
  {
    id: 7,
    title: "Block Fill",
    category: "Title Block Fill",
    subCategory: ["Title Block Fill"],
    description: " Fill Isometric title...",
    subDescription: " Fill Isometric title block from Excel line schedule file..",
    authorName: "Meir Weiss",
    createdAt: "June 03, 2021",
    cover: titleFill1,
    subCover1: titleFill2,
    subCover2: titleFill3,
  }
];
