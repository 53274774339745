import React from 'react'

import './ClientsStyles.css'

import bazan from '../assets/bazan.jpg'
import algatech from '../assets/algatech-logo.jpg'
import elbit from '../assets/elbit.jpg'
import ludan from '../assets/ludan.png'
import teva from '../assets/Teva.jpg'
import tower from '../assets/tower.png'


const Clients = () => {
    return (
        <div className='Clients'>
            <div className='right2' >
                <div className='img-container'>
                    <div className='image-stack '>
                        <img src={algatech} className='img' alt='' />
                    </div>
                    <br/>
                    <div className='image-stack '>
                        <img src={bazan} className='img' alt='' />
                    </div>
                    <br/>
                    <div className='image-stack '>
                        <img src={elbit} className='img' alt='' />
                    </div>
                    <br/>
                    <div className='image-stack '>
                        <img src={ludan} className='img' alt='' />
                    </div>
                    <br/>
                    <div className='image-stack '>
                        <img src={teva} className='img' alt='' />
                    </div>
                    <br/>
                    <div className='image-stack '>
                        <img src={tower} className='img' alt='' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Clients
