import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import ClientsCards from '../components/Clients'
import HeroImage from '../components/HeroImage'

const Clients = () => {
    return (
        <div>
            <Navbar />
            <HeroImage heading='Clients.' text='Our Clients.' />
            <ClientsCards />
            <Footer />
        </div>
    )
}

export default Clients
