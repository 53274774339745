import React from "react";
import "./IT.css";


const Services = () => {
  return (
    <div className="training">
      <div className="left">
        <h1>•installation of virtual software.</h1>
      </div>
      <br/>
      <br/>
      <div className="left">
        <h1>• design of a computer and communication system in the business.</h1>
      </div>
      <br/>
      <br/>
      <div className="left">
        <h1>• computer maintenance.</h1>
      </div>
      <br/>
      <br/>
      <div className="left">
        <h1>
          • protection.
        </h1>
      </div>
      <br/>
      <br/>
      <div className="left">
        <h1>
          • cloud backup.
        </h1>
      </div>
      <br/>
      <br/>
      <div className="left">
        <h1>• information security included in the organization's network.</h1>
      </div>
      <br/>
      <br/>
      <div className="left">
        <h1>• help desk.</h1>
      </div>
    </div>
  );
};

export default Services;
