import React from 'react'
import Footer from '../components/Footer'
import HeroImage from '../components/HeroImage'
import Navbar from '../components/Navbar'
import ServicesSection from '../components/Services'



const Services = () => {
    return (
        <div>
            <Navbar />
            <HeroImage heading='Services.' text='Software development, consulting and support services.' />
            <ServicesSection />
            <Footer />
        </div>
    )
}

export default Services
