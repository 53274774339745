import React from "react";
import AutoCADItem from "./AutoCAD-ProductItem";
import './AutoCAD-ProductList.css'

const AutoCADList = ({ products }) => {
  console.log(JSON.stringify(products))
  return (
    <div className='ProductList-wrap'>
      {products.map((product) => (

        <AutoCADItem key={product.id} product={product} />
      ))}
    </div>
  );
};

export default AutoCADList;
