import React from "react";
import Chip from "./index.js";
import "./AutoCAD-ProductList.css";
//import BlogItem from "./BlogItem";
import { Link } from "react-router-dom";

const AutoCADItem = ({ product: { id, description, title, category, cover,subCovers  } }) => {
 
  return (
    <div className="right2">
      <div className="autoCADItem-wrap">
        <div className="autoCADItem-cover ">
          <img src={cover} className="img" alt="{product}" />
          <Chip label={category} />
          <p className="autoCADItem-title">{title}</p>
          <p className="autoCADItem-desc">{description}</p>
          <footer>
            <div className="autoCADItem-author">
              {/* <img src="" alt="ava>tar" /> */}
            </div>
            <Link className="autoCADItem-link" to={`/autoCAD/${id}`}>
              <h2> ➡️ </h2>
            </Link>
          </footer>
        </div>
        <br />
      </div>
    </div>
  );
};

export default AutoCADItem;
