import React from "react";
import BlogItem from "./BlogItem";
import './BlogList.css'

const BlogList = ({ blogs }) => {
  console.log(JSON.stringify(blogs))
  return (
    <div className='blogList-wrap'>
      {blogs.map((blog) => (

        <BlogItem key={blog.id} blog={blog} />
      ))}
    </div>
  );
};

export default BlogList;
